<template>
  <div class="card searchImageDetailTray" ref="searchImageDetailTray" style="">
    <header class="card-header">
      <div class="card-header-title">
        <router-link :to="authorUrl" class="">
          <img :src="item.avatar" class="avatar" loading="lazy" style="margin-right: 10px; width: 44px; height: 44px" />
        </router-link>
        <router-link :to="authorUrl" class="">
          <p class="title is-5">{{ item.bot }}</p>
        </router-link>
      </div>
      <div class="card-header-icon" aria-label="more options">
        <b-button @click="$emit('prev')" icon-pack="far" type="is-text" rounded :disabled="disablePrev">
          <i class="fas fa-chevron-left" aria-hidden="true"></i>
        </b-button>
        <b-button @click="$emit('next')" icon-pack="far" type="is-text" rounded :disabled="disableNext">
          <i class="fas fa-chevron-right" aria-hidden="true"></i>
        </b-button>

        <DotDrop :horizontal="false" :items="dropDownItems" position="is-bottom-left" />

        <b-button @click="$emit('close')" icon-pack="far" type="is-text" rounded>
          <i class="fas fa-times" aria-hidden="true"></i>
        </b-button>

        <!--  <button class="icon-button">
          <i class="fa fa-times"></i>
        </button>
        <b-button @click="closeBtn(item._id)" icon-pack="far" type="is-text" rounded>
          <i class="fas fa-times" aria-hidden="true"></i>
        </b-button>
      Add more icon buttons here -->
      </div>
    </header>
    <div class="card-image">
      <figure class="image" :src="image" />
      <router-link :to="item.url" class=" ">
        <imgPlaceholder
          :src="item.featuredImage?.url"
          :placeholder="item.featuredImage?.url600 || item.featuredImage?.srcSmall"
        />
      </router-link>
    </div>
    <div class="card-content" style="min-height: 101vh">
      <div class="content">
        <div class="item-header">
          <!-- 
               @dblclick="itemDblCkick(item._id)"

                <img :src="item.gravatar" style="width: 50px; height: 50px; border-radius: 50%; margin-right: 10px" />
   -->
        </div>

        <!--  
            
        <router-link :to="item.url" class=" ">
          <imgPlaceholder
            :src="item.featuredImage?.url"
            :placeholder="item.featuredImage?.url600 || item.featuredImage?.srcSmall"
          />
        </router-link>
         <span :key="item.featuredImage?.url">
          <b-image
            :src="item.featuredImage?.url"
            :placeholder="item.featuredImage?.url600 || item.featuredImage?.srcSmall"
          ></b-image>
        </span>

        <span>
          <img :src="item.featuredImage?.url" class=" " loading=" " />
        </span> 
 

        <p class=" ">
          {{ item.text }}
          <button class="button is-small is-info" @click="$emit('download')">Download</button>
        </p>
   -->
        <h3 class="title is-5">Related content</h3>
        <searchImageMosaic
          :activeImageId="selectedImage?.id"
          :images="relatedPosts"
          @clickImage="clickImage"
          :pageSize="200"
          style="width: 100%"
          :scale="190"
        />

        <!--
        <ul>
          <li v-for="(item, index) in bulletList" :key="index">{{ item }}</li>
        </ul>
  
        <button class="button is-small is-link" @click="$emit('seeMore')">See More</button>  -->
      </div>
    </div>
  </div>
</template>

<script>
import searchImageMosaic from "@/components/search/searchImageMosaic.vue";
import DotDrop from "@/components/e/DotDrop.vue";
import imgPlaceholder from "@/components/e/imgPlaceholder.vue";
import Comments from "@/components/e/comments.vue";

export default {
  //  props: ["q", "scale", "isOwner", "images"],
  props: {
    item: {
      type: Object,
      required: true,
    },

    useRouter: {
      type: Boolean,
      default: false,
    },
    disablePrev: {
      type: Boolean,
      default: true,
    },
    disableNext: {
      type: Boolean,
      default: false,
    },

    /*
    trayImageSequence: {
      type: Array,
      default: function () {
        return [];
      },
    },*/
  },
  components: {
    searchImageMosaic,
    imgPlaceholder,
    DotDrop,
  },

  data() {
    return {
      relatedPosts: [],
    };
  },
  computed: {
    isDetail() {
      return this.context === "detail";
    },
    isFeed() {
      return this.context === "feed";
    },
    isImage() {
      return this.item.featuredImage;
    },
    isText() {
      return !this.item.featuredImage;
    },
    isShortText() {
      if (!this.item.text) return false;
      return this.item.text.length < 100;
    },
    authorUrl() {
      return "/" + this.item.bot;
    },
    isOwner() {
      var userBots = this.$store.main.getters.userBots;
      return userBots.includes(this.item.bot);
      // return this.item.bot === this.$store.main.userId;
    },
    isBookmarked() {
      return this.$store.main.getters.bookmarks.filter((id) => id == this.item.id).length ? true : false;
      //return this.$store.main.getters.bookmarks.includes(this.item.id);
    },

    dropDownItems() {
      var item = this.item;
      if (!item) return [];

      return [
        {
          t: "Add to Bookmarks ", // "Add post to bookmarks",
          icon: "far fa-bookmark",

          hidden: this.isBookmarked,
          click: () => {
            //action in store
            this.$store.main.dispatch("addBookmark", this.item.id).then(() => {
              //
            });

            window.API.me.bookmarkPost({ postId: this.item.id }).then((res) => {
              this.$buefy.snackbar.open({
                message: "Post added to your Bookmarks",
                type: "is-success",
                position: "is-bottom",
                actionText: "view",
                onAction: () => {
                  this.$router.push("/bookmarks");
                },
                indefinite: false,
              });
              //this.item = null;
            });
          },
        },
        {
          t: "Bookmarked",
          icon: "fas fa-bookmark",

          hidden: !this.isBookmarked,
          click: () => {
            // alert(324235);
            /*
            this.$buefy.dialog.confirm({
              message: "Delete this post?",
              type: "is-danger",
              onConfirm: () => {*/
            // var img = this.item.featuredImage?.url300;
            //  alert(img);
            // window.API[method](d.handle, d).then((bot) => {
            this.$store.main.dispatch("removeBookmark", this.item.id).then(() => {
              //
            });
            window.API.me.unbookmarkPost(this.item.id).then((res) => {
              //TODO: if the current view is bookmarks, we need to remove the item from the list
              //emit event to parent
              this.$emit("bookmarkRemoved", this.item.id);
              this.$buefy.snackbar.open({
                message: "Post removed from your Bookmarks",
                type: "is-dark",
                position: "is-bottom",
                actionText: "Close",
                indefinite: false,
              });
            });
          },
        },

        {
          t: "View post",
          icon: "far fa-eye",
          to: this.item.url,
          hidden: this.isDetail,
        },
        /*
        {
          t: "View profile",
          visible: !this.isOwner,
          icon: "far fa-user",
          to: this.authorUrl,
        },*/
        {
          t: "Comments",
          icon: "far fa-comments",
          to: this.item.url,
          hidden: false,
        },

        {
          t: "Edit...",
          icon: "far fa-edit",
          hidden: !this.isOwner,
          to: this.item.url + "/edit",
        },
        {
          t: "Use as profile pic",
          icon: "far fa-image",
          hidden: !this.isOwner || !this.isImage,
          click: () => {
            // alert(324235);
            /*
            this.$buefy.dialog.confirm({
              message: "Delete this post?",
              type: "is-danger",
              onConfirm: () => {*/
            var img = this.item.featuredImage?.url300;
            //  alert(img);
            // window.API[method](d.handle, d).then((bot) => {

            window.API.editBot(this.item.bot, { avatar: img }).then((res) => {
              this.$buefy.snackbar.open({
                message: "Profile picture updated",
                type: "is-success",
                position: "is-bottom",
                actionText: "Close",
                indefinite: false,
              });
              //this.item = null;
            });

            //this.$buefy.toast.open('User confirmed')

            // });
          },
        },

        {
          t: "Flag for Re classification...",
          icon: "far fa-cogs",
          hidden: !this.isOwner,
          click: () => {
            // alert(324235);

            this.$buefy.dialog.confirm({
              message: "Request an image re-classiication?",
              type: "is-info",
              onConfirm: () => {
                window.API.editBotPost(this.item.bot, this.item.id, { flaggedForReclassify: true }).then((res) => {
                  this.$buefy.snackbar.open({
                    message: "reclassification requested",
                    type: "is-warning",
                    position: "is-bottom",
                    actionText: "Close",
                    indefinite: false,
                  });
                  this.item = null;
                });

                //this.$buefy.toast.open('User confirmed')
              },
            });
          },
        },

        {
          t: "Delete this post...",
          icon: "far fa-trash",
          hidden: !this.isOwner,
          click: () => {
            // alert(324235);

            this.$buefy.dialog.confirm({
              message: "Delete this post?",
              type: "is-danger",
              onConfirm: () => {
                window.API.editBotPost(this.item.bot, this.item.id, { deleted: true }).then((res) => {
                  this.$buefy.snackbar.open({
                    message: "Post deleted",
                    type: "is-warning",
                    position: "is-bottom",
                    actionText: "Close",
                    indefinite: false,
                  });
                  this.item = null;
                });

                //this.$buefy.toast.open('User confirmed')
              },
            });
          },
        },

        /*
        {
          t: "Email details...",
          icon: "far fa-info",
          click: () => {
            this.showMeta = !this.showMeta;
          },
        },
        {
          t: "Copy link",
          icon: "far fa-link",
          click: () => {
            this.copyLink(item);
          },
        },

        { t: "Open...", icon: "far fa-eye", to: this.getMsgDetailPath(item._id) },

        { t: "Gmail Fwd ", icon: "far fa-envelope", href: this.getMailtoHref(item, "fw") },
        { t: "Gmail Re ", icon: "far fa-envelope", href: this.getMailtoHref(item) },

        {
          t: "Info ",
          icon: "far fa-info-circle",

          click: this.toggleMeta,
        },
        {
          t: "Summarize  ",
          icon: "far fa-info-magic",

          click: this.getEmailSummary,
        },

        {
          t: this.format == "html " ? "Plain text" : "HTML",
          icon: "far fa-" + (this.format == "html " ? "file-alt" : "code"),

          click: () => {
            this.format = this.format == "html" ? "text" : "html";
          },
        },*/
      ];
    },

    hasSnipet() {
      return false; // this.item.code || this.item.action;
    },
    formatedPlainText() {
      // NO WORK!
      var t = this.item;
      return ""; // formatPlainTextInHtml(t);
    },
    //get current route
    isDetailModalActive() {
      return this.$route.params.messageId;
    },
    selectedEmail() {
      return this.messages.filter((message) => {
        console.log(message._id, this.$route.params.messageId, 5558874);
        return message._id === this.$route.params.messageId;
      })[0];
      // return this.$store.state.selectedEmail;
    },
    likes() {
      var ll = this.item.likes || [];
      // if (this.hasClickedLike) ll.push(this.$store.main.getters.userId);
      return ll;
    },
    isLiked() {
      if (this.hasClickedLike) return true;
      return this.likes.includes(this.$store.main.getters.userId);
    },
    likeCount() {
      //account for hasClickedLike
      //if (this.hasClickedLike) return this.likes.length + 1;
      return this.likes.length || 0;
    },
    images() {
      var images = this.item.images || [];
      images = images.map((i) => {
        i.style = `aspect-ratio: ${i.width}/${i.height}`;
        return i;
      });
      return images;
    },
    featuredImage() {
      var images = this.images;
      if (images.length) return images[0];
      return null;
    },
  },
  mounted() {
    this.loadPost();
  },
  //watch
  watch: {
    /*
    $route(to) {
      this.loadPost(to.params.postId);
    },*/
    //wathc the item prop
    item: {
      handler: function (val, oldVal) {
        //console.log('new: %s, old: %s', val, oldVal)
        this.goTop();
        this.loadPost();
      },
      deep: true,
    },
  },
  methods: {
    clickImage(i) {
      this.goTop();
      i.sequence = this.relatedPosts; //ADD CURRENT sequence
      this.$emit("clickImage", i);
    },
    goTop() {
      //scroll to top of component.
      var ref = this.$refs.searchImageDetailTray;
      ref.scrollTop = 0;
    },
    async loadPost(postId) {
      // this.post = {};
      this.bot = {};
      this.relatedPosts = [];
      const postIdToLoad = this.item.id;
      const botId = this.item.bot;
      window.API.getRelatedBotPosts(this.item.bot, postIdToLoad).then((r) => {
        this.relatedPosts = r;
      });
      window.API.getBotPost(botId, postIdToLoad)
        .then((p) => {
          this.post = p;
          //this.checkIfAllCallsAreCompleted();
          //window.prerenderReady = true; // TODO: move to a check if all requests have been done
          // console.log("PROJJ!", proj, proj.id);
        })
        .catch((e) => {
          console.error("Error loading post", e);
          this.post = {};
        });
      /*
      window.API.botProfile(this.botId)
        .then((b) => {
          this.bot = b;
          this.loadingBot = false;
          //this.checkIfAllCallsAreCompleted();
          //window.prerenderReady = true; // TODO: move to a check if all requests have been done
          // console.log("PROJJ!", proj, proj.id);
        })
        .catch((e) => {
          console.error("Error loading bot", e);
          this.loadingBot = false;
          this.bot = {};
        });*/
    },
  },
};
</script>
<style scoped>
.searchImageDetailTray {
  margin-top: 15px;
  margin-bottom: 15px;
  margin-right: 15px;
  /*
  position: fixed;*/
  overflow: scroll;
  height: 600px;
  height: calc(100vh - 20px);
  width: -webkit-fill-available; /* to ensure it takes full width when smaller images re there, avoid placeholder flickting. */
}
.imgGrid .image img {
  max-width: 100%;
  min-width: 0 !important;
  min-height: 0 !important;
}
</style>
