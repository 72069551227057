<template>
  <div class="masso2">
    <div
      v-for="i in images"
      :key="i.id"
      class="item"
      :class="{ active: activeImageId == i.id }"
      :style="{
        width: Math.floor(i.featuredImage?.ratio * MAGIC_MOSAIC_SCALE) + 'px',
        'flex-grow': String(i.featuredImage?.ratio),
      }"
    >
      <!-- {{ String(1 + " / " + i.featuredImage?.ratio) }} -->

      <router-link v-if="useRouter" :to="i.url + (q ? '?q=' + q : '')">
        <figure>
          <img :src="i.featuredImage?.url600 || i.srcSmall" loading="lazy" class="stock" :alt="i.prompt" />
        </figure>
      </router-link>

      <div v-else @click="clickImage({ id: i.id, bot: i.bot, post: i })">
        <figure class="img-wrap">
          <img :src="i.featuredImage?.url600 || i.srcSmall" loading="lazy" class="stock" :alt="i.prompt" />
        </figure>

        <p>
          <img :src="i.avatar" class="avatar" loading="lazy" style="margin-right: 10px; width: 14px; height: 14px" />

          <span>{{ i.bot }}</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  //  props: ["q", "scale", "isOwner", "images"],
  props: {
    q: {
      type: String,
      default: "",
    },
    scale: {
      type: Number,
      default: 190,
    },
    isOwner: {
      type: Boolean,
      default: false,
    },
    useRouter: {
      type: Boolean,
      default: false,
    },
    activeImageId: {
      type: String,
      default: "",
    },
    images: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },

  data() {
    return {
      MAGIC_MOSAIC_SCALE: this.scale,
    };
  },
  methods: {
    clickImage(i) {
      this.$emit("clickImage", i);
    },
  },
};
</script>
<style scoped>
.imgGrid .image img {
  max-width: 100%;
  min-width: 0 !important;
  min-height: 0 !important;
  cursor: pointer;
}

.item {
  border: 1px solid transparent;
}
.active {
  /* 
  border: 1px solid #86f9ff;
  border-radius: 3px;
  background: #bdfcff;
  border-radius: 5px;*/
  background: rgb(183, 247, 255);
  border: 1px solid #3dd7e0;
}

.active img {
  /* darken 50% dark bg backing */

  opacity: 0.7;
  background: black;
}
.img-wrap {
  background: black;
  border-radius: 13px;
  display: flex;
}

.active .img-wrap {
  opacity: 1;
}
/*
.active figure::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.3s ease;
  opacity: 0.5;
  transition: opacity 0.3s ease;
}

/* lists */
img.stock {
  background: transparent;
  max-height: 60vh;
  object-fit: contain;
  object-fit: cover;
  height: 100%;
  width: 100%;
} /*
.masso {
  display: flex;
  flex-flow: row wrap;
  margin-left: -8px;
  width: 100%;
}

.masso a {
  flex: auto;
  height: 180px;
  min-width: 150px;
  margin: 0 8px 8px 0;
}
*/
.masso2 a:hover {
  opacity: 0.7;
}

.item a {
  display: block;
  width: 100%;
  height: 100%;
}
.masso2 {
  display: flex;
  flex-wrap: wrap;
}

.masso2 .item {
  display: flex;
  margin: 1px 2px;
  margin: 15px 0px;
  position: relative;
  padding: 9px;
  border: 1px white transparent;
  cursor: pointer;
  border-radius: 7px;
}
.masso2 .item:hover {
  border-radius: 3px;
  /*
  background: #bdfcff;
  border-radius: 5px;*/
  /* drop shdow */
}
.masso2 .item:hover img:not(.avatar) {
  box-shadow: 0 0 0 1px #333, 0 0 0 3px #333;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.3);
}
.masso2 img {
  border-radius: 13px;
}
.masso2 figure {
  margin: 0;
}
p {
  margin-top: 5px;
}

/*
.masso a::before {
  counter-increment: grid;
  content: counter(grid);
}

.masso a:nth-child(3n) {
  aspect-ratio: 1;
  background: lavender;
}

.masso a:nth-child(3n - 1) {
  aspect-ratio: 2 / 3;
  background: lightblue;
}
*/

/*
.masso a > figure,
.masso img {
  height: 100%;
  border-radius: 3px;
  margin: 0;
}

.masso:nth-child(4n + 1) {
  width: 250px;
}
.masso:nth-child(4n + 1):nth-child(4n + 2) {
  width: 325px;
}
.masso:nth-child(4n + 1):nth-child(4n + 3) {
  width: 180px;
}
.masso:nth-child(4n + 1):nth-child(4n + 4) {
  width: 380px;
}*/
</style>
