<template>
  <div class="columns is-variable is-0" :class="{ isSearchResults: isSearchResults }">
    <!--
    <br />
    {{ selectedImage == null }}
    <br />
    <br />
    {{ initialSelectedImage?.id || "no img selected initially" }}

      -->
    <div class="column" style="padding-right: 30px">
      <searchImageMosaic
        :scale="190"
        trayImageSequence="trayImageSequence"
        :activeImageId="selectedImage?.id"
        :images="images"
        @clickImage="clickImageMosaic"
        :pageSize="200"
        style="width: 100%"
      />
    </div>
    <div class="column is-4" v-if="selectedImage" style="min-width: 480px; max-width: 780px">
      <div class="img-tray-wrap sticky-content">
        <searchImageDetailTray
          :item="selectedImage"
          @clickImage="clickImage"
          @close="selectedImage = null"
          :disablePrev="trayCurrentIndex == 0"
          :disableNext="false"
          @next="nextImage(1)"
          @prev="nextImage(-1)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import searchImageDetailTray from "@/components/search/searchImageDetailTray.vue";
import searchImageMosaic from "@/components/search/searchImageMosaic.vue";
//import imageSearchResults from "@/components/search/imageSearchResults.vue";
export default {
  components: {
    searchImageDetailTray,
    searchImageMosaic,
  },
  props: {
    images: {
      type: Array,
      default: () => [],
    },
    initialTrayImageSequence: {
      type: Array,
      default: () => [],
    },
    initialSelectedImage: {
      type: Object,
      default: null,
    },
    /*
    closeImageTrayTime: {
      //to trigger - dirty...
      type: Date,
      default: null,
    },*/
    closeTrayOnImageChanges: {
      type: Boolean,
      default: true,
    },
    isSearchResults: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    initialTrayImageSequence(newSequence) {
      console.log("initialTrayImageSequence;🔸-🔸🔸🔸-🔸-;;", newSequence);
      this.trayImageSequence = newSequence;
    },
    initialSelectedImage(newImage) {
      console.log("initialSelectedImage;🔸-🔸-🔸-;;", newImage);
      this.selectedImage = newImage;
    },
    images(n) {
      if (this.closeTrayOnImageChanges) this.selectedImage = null;
    },
  },
  created() {
    // When component is created, set initial values from props
    this.selectedImage = this.initialSelectedImage;
    this.trayImageSequence = this.initialTrayImageSequence;
  },

  data() {
    return {
      //selectedImage: this.initialSelectedImage,
      //  trayImageSequence: this.initialTrayImageSequence || [],
      selectedImage: null, // initially set as null, will update in created hook
      trayImageSequence: [], // initially set as empty, will update in created hook
    };
  },
  computed: {
    trayCurrentIndex() {
      var ar = this.trayImageSequence || [];
      var i = ar.findIndex((p) => {
        return p.id == this.selectedImage?.id;
      });
      //console.log(i, 897987987, this.trayImageSequence, this.selectedImage?.id);
      //  console.log(this.trayImageSequence);
      return i;
    },
  },
  methods: {
    clickImageMosaic(i) {
      i.sequence = this.images;
      this.clickImage(i);
    },

    clickImage({ bot, id, post, sequence }) {
      //  alert(id + bot);
      this.selectedImage = post;
      //copy entire object clone
      var ar = sequence || [];
      console.log("SEQEUNCE", ar);
      this.trayImageSequence = [...ar];
    },
    nextImage(oneOrMinusOne) {
      var currentIndex = this.trayCurrentIndex;
      var newIndex = currentIndex + oneOrMinusOne;
      if (newIndex >= this.trayImageSequence.length) newIndex = 0;
      var selectedImage = this.trayImageSequence[newIndex];
      // this.clickImage({ post: this.selectedImage });
      this.selectedImage = selectedImage;
    },

    closeImage() {
      //this.$emit("setSelectedImage", null);
    },
  },
};
</script>
<style scoped>
.sticky-content {
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 20px; /* or whatever offset you want from the top */
  z-index: 100; /* optional, to make sure it's on top */
}
.isSearchResults .sticky-content {
  top: 80px;
}

.isSearchResults .sticky-content >>> .searchImageDetailTray {
  height: calc(100vh - 60px);
}
</style>
